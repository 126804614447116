<template>
  <Network ref="test" id="network"
           :nodes="network_nodes"
           :edges="network_edges"
           :options="network_options"
  />
</template>

<script>
import netdocService from '@/api-services/netdoc.service'
import {Network} from 'vue-vis-network'

export default {
  name: 'PPortNetwork',
  components: { Network },
  props: {
    p_port: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      network_nodes: [],
      network_edges: [],
      network_options: {
        nodes: {
          borderWidth: 4,
          font: {
            color: 'white',
            face: 'arial',
            align: 'center',
          }
        },
        edges: {
          color: 'lightgray'
        },
        layout: {
          hierarchical: {
            direction: 'Left-Right',
            // sortMethod: 'directed'
          }
        }
      }
    }
  },
  async created() {
    await this.refresh_data()
  },
  watch: {
    $props() {
      this.refresh_data()
    }
  },
  methods: {
    async refresh_data() {
      this.bcd_name = this.$route.params.name
      netdocService.find_path_for_p_port(this.$store.state, this.p_port).then(r => {
        for (const p of r.data.module_list) {
          this.network_nodes.push({ id: p.fq_name, label: p.fq_name.trim(), shape: 'box', color: p.fq_name.toHSL() })
          if (p.parent_fq_slot) {
            this.network_edges.push({ from: p.fq_name, to: p.parent_fq_slot })
          }
        }

        for (const p of r.data.p_port_list) {
          this.network_nodes.push({
            id: p.gpk,
            label: p.name.trim(),
            shape: 'circle',
            font: { align: 'right' },
            color: p.mdl_fq_name.toHSL()
          })
          if (p.connected_gfk && p.gpk !== p.connected_gfk) {
            // if prevents duplicated edges
            if (!this.network_edges.some(e => e.from === p.connected_gfk && e.to === p.gpk)) {
              this.network_edges.push({ from: p.gpk, to: p.connected_gfk })
            }
          }
          if (p.mdl_fq_name) {
            this.network_edges.push({ from: p.gpk, to: p.mdl_fq_name })
          }
        }
        this.$refs.test.fit()
        // this.$refs.test.stabilize(100)
        this.$refs.test.stopSimulation()
        setTimeout(() => this.$refs.test.fit(), 20)
      })
    }
  }
}
</script>

<style scoped>
#network {
  width: 100%;
  height: 60vh;
}
</style>
